<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div class="card" v-if="!loading">
    <div class="card-header">
      <h3 class="card-title" style="font-size: 20px; font-weight: bold">
        <p>{{ $t("warehouse") }}</p>
      </h3>
      <div class="card-toolbar">
        <button
          type="button"
          class="btn btn-sm btn-light btn-primary"
          style="font-size: 14px; font-weight: bold"
          @click="goToNew"
        >
          <span class="svg-icon svg-icon-2x">
            <inline-svg src="media/Flatten.svg" /> </span
          >{{ $t("add") }}
        </button>
      </div>
    </div>

    <div class="card-title row ms-6 me-5 mt-6">
      <div class="col-sm-3 mb-3">
        <label for="validationTooltip01" class="form-label col-sm-10">{{
          $t("warehousetype")
        }}</label>
        <select
          :style="
            isSubmit
              ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
              : ''
          "
          v-model="warehouse_type"
          class="form-select"
        >
          <option value="คลังหลัก">คลังหลัก</option>
          <option value="คลังรอง">คลังรอง</option>
          <option value="คลังอื่นๆ">คลังอื่นๆ</option>
        </select>
      </div>

      <div class="col-sm-5 mb-3">
        <label
          class="
            form-check form-check-sm form-check-custom form-check-solid
            me-5
            mt-12
          "
        >
          <el-checkbox
            v-model="is_sale_warehouse"
            type="checkbox"
            :value="true"
          />
          <span class="form-check-label">
            {{ $t("warehouses") }}
          </span>
        </label>
      </div>

      <div class="col-sm-3 mb-3 text-end">
        <label class="form-label col-sm-10"></label>
        <input
          v-model="searchInput"
          :placeholder="$t(`search`)"
          type="text"
          class="form-control"
        />
      </div>
      <div class="col-sm-1 text-end">
        <label class="form-label col-sm-10"></label>
        <button
          @click="search()"
          type="button"
          class="btn btn-sm btn-light-primary"
          style="font-size: 14px; font-weight: bold"
        >
          <th>{{ $t("search") }}</th>
        </button>
      </div>
    </div>

    <div class="card-body pt-3">
      <!-- searchbar -->
      <!-- <Search @textSearch="textSearch" /> -->
      <!-- searchbar -->

      <div class="table-responsive">
        <table
          class="table table-hover table-rounded table-striped border gy-7 gs-7"
        >
          <thead>
            <tr
              class="fw-bold fs-5 text-gray-800 border-bottom-2 border-gray-200"
            >
              <th>{{ $t("no") }}</th>
              <th>{{ $t("warehousecode") }}</th>
              <th>{{ $t("initials") }}</th>
              <th>{{ $t("warehousename") }}</th>
              <th>{{ $t("type") }}</th>

              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="dataItemsShow.length < 1">
              <td class="text-center" colspan="6">ไม่มีข้อมูล</td>
            </tr>
            <tr v-for="(item, index) in dataItemsShow" :key="index">
              <!-- <td>
                <input
                  type="checkbox"
                  @change="isChecked(item)"
                  :checked="checkAll ? true : false"
                />
              </td> -->
              <td>{{ !showing1 ? index + 1 : showing1 + index }}</td>
              <td>{{ item.warehouse_code }}</td>
              <td>{{ item.warehouse_short_name }}</td>
              <td>{{ item.warehouse_full_name }}</td>
              <!-- @click="item.warehouse_type !== '1' ? handleApprove(item) : ''" -->
              <td
                :style="
                  item.warehouse_type === 'คลังหลัก'
                    ? 'cursor: ; color: green'
                    : item.warehouse_type === 'คลังรอง'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              >
                {{ item.warehouse_type }}
              </td>
              <!-- <td
                @click="item.status !== '1' ? handleApprove(item) : ''"
                :style="
                  item.status === '1'
                    ? 'cursor: ; color: green'
                    : item.status === '0'
                    ? 'cursor: pointer; color: blue'
                    : 'cursor: pointer; color: red'
                "
              >
                {{
                  item.status === "1"
                    ? "อนุมัติ"
                    : item.status === "0"
                    ? "รออนุมัติการเพิ่ม"
                    : "ไม่อนุมัติ"
                }}
              </td> -->

              <td>
                <div class="row">
                  <div class="col-sm-6 d-flex justify-content-md-end">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-success
                        p-0
                      "
                      @click="goToEdit(item.id)"
                    >
                      <i class="bi bi-pencil-square"></i>
                    </button>
                  </div>
                  <div class="col-sm-6 d-flex">
                    <button
                      type="button"
                      class="
                        btn btn-link btn-color-muted btn-active-color-danger
                        p-0
                      "
                      @click="goToDelete(item, index)"
                    >
                      <i class="bi bi-trash3-fill"></i>
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <DialogConfirmByPass
      :dialogConfirmByPass="dialogConfirmByPass"
      :loadingUpdate="loadingUpdate"
      @submitConfirmPass="submitConfirmPass"
      @closeDialogConformByPass="closeDialogConformByPass"
    />

    <div class="card-footer">
      <Pagination
        :items="tableItems"
        @filterItems="filterItems"
        @handleChangePage="handleChangePage"
      />
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import whApi from "@/api/warehouse/";

import DialogConfirmByPass from "../../components/dialogConfirmByPass.vue";
import Pagination from "../../components/pagination.vue";
import Loader from "../../components/loaderIndex.vue";
import Search from "../../components/search.vue";

export default {
  setup() {
    document.title = "bem-document-demo คลังสินค้า";
  },
  components: {
    DialogConfirmByPass,
    Pagination,
    Search,
    Loader,
  },
  data: () => ({
    loading: false,
    loadingUpdate: false,
    dataItemsShow: [],
    showing1: null,

    checkAll: false,
    dialogConfirmByPass: false,

    tableItems: [],
    itemApprove: {},

    searchInput: "",
    warehouse_type: "",
    is_sale_warehouse: "",
    allData: [],
    // oldSearchInput: 0,
  }),

  watch: {
    checkAll(val) {
      if (val) {
        this.checkbox = this.dataItemsShow;
        this.newCheckbox = this.dataItemsShow;
      } else {
        this.checkbox = [];
        this.newCheckbox = [];
      }
    },
    // searchInput(val) {
    //   if (this.oldSearchInput !== 0) {
    //     if (
    //       val.length - this.oldSearchInput === -1 ||
    //       val.length - this.oldSearchInput === 1
    //     ) {
    //       this.dataItemsShow = this.allData;
    //       this.tableItems = this.allData;
    //     } else if (val.length - this.oldSearchInput === -this.oldSearchInput) {
    //       this.dataItemsShow = this.allData;
    //       this.tableItems = this.allData;
    //     }
    //   }
    // },
  },

  created() {
    this.getAll();
  },
  methods: {
    async getAll(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.warehouse.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.dataItemsShow = responseData.data;
        this.tableItems = responseData.data;
        this.allData = responseData.data;
        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    //--- Pagination --- start
    filterItems(val) {
      this.dataItemsShow = val;
    },

    handleChangePage(val, showing1) {
      this.dataItemsShow = val;
      this.showing1 = showing1;
    },
    //--- Pagination --- end

    goToNew() {
      // this.$router.push({ name: "amount-quoted-new" });
      this.$router.push({
        path: "/warehouse/WH1/new",
        query: {},
      });
    },
    goToEdit(id) {
      this.$router.push({
        name: "WH1-edit",
        query: {
          id: id,
        },
      });
    },
    async goToDelete(item, index) {
      Swal.fire({
        icon: "question",
        title: `ต้องการลบ ${item.warehouse_short_name} หรือไม่ ?`,
        confirmButtonText: "ตกลง",
        cancelButtonText: "ยกเลิก",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.confirmDelete(item, index);
        }
      });
    },
    async confirmDelete(item, index) {
      let deleteResponse = [];
      deleteResponse = await whApi.warehouse.delete(item.id);
      if (deleteResponse.response_status === "SUCCESS") {
        Swal.fire({
          icon: "success",
          title: "ลบสำเร็จ",
          showConfirmButton: false,
          timer: 1500,
        }).then(() => {
          this.dataItemsShow.splice(index, 1);
          this.tableItems.splice(index, 1);
          this.getAll(1);
        });
      }
    },
    async statusApprove(isApprove) {
      let updateResponse = [];
      const id = this.itemApprove.id;

      this.itemApprove = {
        ...this.itemApprove,
        status: isApprove ? 1 : 2,
      };
      updateResponse = await whApi.warehouse.update(id, this.itemApprove);
      if (updateResponse.response_status === "SUCCESS") {
        if (isApprove) {
          Swal.fire({
            icon: "success",
            title: `อนุมัติสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `ไม่อนุมัติ`,
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.loadingUpdate = false;
        this.getAll(1);
      } else {
        Swal.fire({
          icon: "warning",
          title: "อนุมัติไม่สำเร็จ !",
          showConfirmButton: false,
          timer: 1500,
        });
        this.loadingUpdate = false;
      }
    },
    handleApprove(item) {
      this.itemApprove = { ...item };
      this.dialogConfirmByPass = true;
    },
    async submitConfirmPass(isApprove) {
      this.statusApprove(isApprove);
      this.dialogConfirmByPass = false;
    },
    closeDialogConformByPass() {
      this.dialogConfirmByPass = false;
    },
    textSearch(val) {
      console.log({ val });
    },

    async search() {
      this.loading = true;

      const responseSearch = await whApi.warehouse.search({
        warehouse_type: this.warehouse_type,
        is_sale_warehouse: this.is_sale_warehouse,
        search: this.searchInput,
      });

      if (responseSearch.response_status === "SUCCESS") {
        this.dataItemsShow = responseSearch.data;
        this.tableItems = responseSearch.data;
        this.allData = responseSearch.data;
        this.loading = false;
      }
    },
  },
};
</script>
